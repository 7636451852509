import React from "react"
import Layout from '../../components/layout'
import Section from '../../components/section'
import Banner from '../../components/banner'
import Container from '../../components/container'
import Typography from '../../components/typography'
import Seo from '../../components/seo';

export default function AsBuiltPlansPreparation(props) {
  return (
    <Layout {...props}>
      <Seo title="As Built Plans Preparations" description="Deliver service for as built plans of different establishments for Mechanical, Electrical, Plumbing, Fire Protection Systems." />
      <Section>
        <Banner 
          src="/img/sample-hero-image-1.jpg"
          header="As Built Plans Preparations"
        />
      </Section>
      <Section>
        <Container>
          <Typography component="p">
            Deliver service for as built plans of different establishments for Mechanical, Electrical, Plumbing,
            Fire Protection Systems.
          </Typography>
        </Container>
      </Section>
    </Layout>
  )
}
